<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="w-100">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">404</h1>
          <h4 class="pt-3">Ups! Usted esta perdido.</h4>
          <p class="text-muted">La página que está buscando no fue encontrada.</p>
          <CButton color="primary" @click="goBack">Regresar a la pantalla anterior</CButton>
        </div>
      </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'Page404',
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  }
}
</script>
